
import http from "../../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch
} from "vue";
import moment from "moment";
import router from "../../router";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner },
  setup(props) {
    const form = ref({});

    const farms = ref([]);
    const farm_id = ref(0);

    const farmName = computed(() => {
      const n = farms.value?.find((f: any) => f.id == farm_id.value);

      return n?.["name"];
    });

    const varieties = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);
    const day = ref(0);
    const date = ref(new Date());

    const today = computed(() => new Date());

    const prod = ref(1);

    function wednesday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 3
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 2))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 2));
    }
    const start = ref(wednesday());
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    const masks = ref({
      input: "YYYY-MM-DD"
    });

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function fetchFarms() {
      processing.value = true;
      http.get(`/api/farms?fetch=all`).then(res => {
        farms.value = res.data;
        processing.value = false;
      });
    }

    async function fetchPicks(f: any) {
      const res = await http.get(
        `/api/picks?farm_id=${f}&start=${fD(
          start.value,
          "yyyy-MM-dd"
        )}&end=${fD(end.value, "yyyy-MM-dd")}`
      );

      return res.data.data;
    }

    function fetchVarieties(id: any) {
      processing.value = true;

      fetchPicks(id).then((ps: any) => {
        http.get(`/api/varieties?fetch=all`).then(res => {
          const a = res.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            if (p === undefined) {
              return {
                ...o,
                [d]: a.reduce(
                  (r: any, v: any) => ({
                    ...r,
                    [v.id]: {
                      projected: 0,
                      ammended: 0,
                      declared: 0,
                      intake: 0,
                      dnote:  0,
                      fpackout: 0,
                      crates: 0,
                      tpackout: 0,
                      comment: ""
                    }
                  }),
                  {}
                )
              };
            } else {
              return {
                ...o,
                [d]: a.reduce(
                  (r: any, v: any) => ({
                    ...r,
                    [v.id]: {
                      projected:
                        ((p?.quantities as any)[v.id] as any)?.projected || 0,
                      ammended:
                        ((p?.quantities as any)[v.id] as any)?.ammended || 0,
                      declared:
                        ((p?.quantities as any)[v.id] as any)?.declared || 0,
                      intake: ((p?.quantities as any)[v.id] as any)?.intake || 0,
                      dnote: ((p?.quantities as any)[v.id] as any)?.dnote || 0,
                      fpackout:
                        ((p?.quantities as any)[v.id] as any)?.fpackout || 0,
                      crates: ((p?.quantities as any)[v.id] as any)?.crates || 0,
                      tpackout:
                        ((p?.quantities as any)[v.id] as any)?.tpackout || 0,
                      comment:
                        ((p?.quantities as any)[v.id] as any)?.comment || ""
                    }
                  }),
                  {}
                )
              };
            }
          }, {});

          processing.value = false;
        });
      });
    }

    function searchVarieties() {
      fetchPicks(farm_id.value).then((ps: any) => {
        http.get(`/api/varieties?s=${search.value}&fetch=all`).then(res => {
          const a = res.data.data;
          varieties.value = a;

          const dates = getDates(start.value);

          form.value = dates.reduce((o, d) => {
            const p: any = ps.find((or: any) => or.date == d);

            return {
              ...o,
              [d]: a.reduce(
                (r: any, v: any) => ({
                  ...r,
                  [v.id]: {
                    projected:
                      ((p?.quantities as any)[v.id] as any)?.projected || 0,
                    ammended:
                      ((p?.quantities as any)[v.id] as any)?.ammended || 0,
                    declared:
                      ((p?.quantities as any)[v.id] as any)?.declared || 0,
                    intake: ((p?.quantities as any)[v.id] as any)?.intake || 0,
                    dnote: ((p?.quantities as any)[v.id] as any)?.dnote || 0,
                    fpackout:
                      ((p?.quantities as any)[v.id] as any)?.fpackout || 0,
                    tpackout:
                      ((p?.quantities as any)[v.id] as any)?.tpackout || 0,
                    comment:
                      ((p?.quantities as any)[v.id] as any)?.comment || ""
                  }
                }),
                {}
              )
            };
          }, {});
        });
      });
    }

    function savePicks() {
      http
        .post("/api/picks", {
          farm_id: farm_id.value,
          picks: form.value,
          start: start.value,
          end: end.value
        })
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          fetchVarieties(farm_id.value);
        });
    }

    function setDay(i = 0, d: any) {
      day.value = i;
      date.value = d;
    }

    function setProd(n: 0) {
      prod.value = n;
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    onMounted(() => {
      fetchFarms();

      if (router.currentRoute.value.query?.farm_id) {
        const f = router.currentRoute.value.query?.farm_id;

        farm_id.value = Number(f);
        fetchVarieties(f);
      } else {
        farm_id.value = 1;
        fetchVarieties(1);
      }
    });

    watch(
      () => end.value,
      (v: any) => {
        fetchVarieties(farm_id.value);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      savePicks,
      created,
      updated,
      fD,
      today,
      start,
      end,
      masks,
      getDates,
      setDay,
      day,
      date,
      form,
      farms,
      farmName,
      farm_id,
      prod,
      setProd,
      getPercentage
    };
  }
});
